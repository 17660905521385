const clickBtn = id => new Promise(resolve => {
  const modalBtn = document.getElementById(id)
  if (modalBtn) {
    modalBtn.click()
    resolve()
  }
})

export const showOverlay = (show = true) => {
  const overlay = document.getElementById('tour-overlay')
  const driverPopover = document.querySelector('.driver-popover')

  if (show) {
    overlay.lastChild.classList.add('d-block')
    driverPopover.classList.add('d-none')
  } else {
    overlay.lastChild.classList.remove('d-block')
  }
}

export const hideOverlay = () => {
  window.scrollTo(0, 0)
  showOverlay(false)
}

const tourHelper = (tourDriver, store, { router, route }) => {
  const openModal = async (id, direction, idType = 'modal', styles = null) => {
    let target
    if (idType === 'modal') await clickBtn(id)
    else {
      target = document.querySelector(`[aria-labelledby="${id}"]`)
      target.classList.add('show')
    }

    if (direction === 'go-next') tourDriver.moveNext()
    else if (direction === 'go-prev') tourDriver.movePrevious()
    if (idType === 'dropdown') {
      setTimeout(() => {
        target.classList.add('show')
        if (styles) {
          target.style = styles
          tourDriver.refresh()
        }
      }, 550)
    }
  }

  const closeModal = (id, direction, idType = 'modal') => {
    if (idType === 'modal') {
      const closeBtn = document.getElementById(id)?.lastElementChild
      if (closeBtn) closeBtn.click()
    }
    if (direction === 'go-next') tourDriver.moveNext()
    else if (direction === 'go-prev') tourDriver.movePrevious()
    window.scrollTo(0, 0)

    if (idType === 'dropdown') {
      document.querySelector(`[aria-labelledby="${id}"]`).classList.remove('show')
    }
  }

  const pushRouter = name => new Promise(resolve => {
    if (route.value.name !== name) {
      router.push({ name }).then(() => {
        const checkComponentReady = setInterval(() => {
          if (store.state.tour.isComponentReady) {
            clearInterval(checkComponentReady)
            store.dispatch('tour/setComponentReady', null)
            resolve()
          }
        }, 600)
      })
    } else resolve()
  })

  return {
    clickBtn,
    openModal,
    closeModal,
    pushRouter,
  }
}

export default tourHelper
