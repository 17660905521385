import { driver } from 'driver.js'
import store from '@/store'
import tourHelper from './tourHelper'
// eslint-disable-next-line import/no-cycle
import ownerTourSteps from './ownerTourSteps'
// eslint-disable-next-line import/no-cycle
import memberTourSteps from './memberTourSteps'

const ownerRole = JSON.parse(localStorage.getItem('userData'))?.companyRoles?.data.find(companyRole => companyRole?.role.name === 'owner')
const userType = ownerRole ? 'owner' : 'member'

let tourDriver

// Components
export const setComponentReady = () => {
  if (store.state.tour.isTouring) store.dispatch('tour/setComponentReady', true)
}

export const checkComponentReady = () => new Promise(resolve => {
  const interval = setInterval(() => {
    if (store.state.tour.isComponentReady) {
      clearInterval(interval)
      store.dispatch('tour/setComponentReady', null)
      resolve()
    }
  }, 250)
})

// Tour Actions
export const pauseTour = () => {
  if (store.state.tour.isTouring && tourDriver) {
    store.dispatch('tour/pauseTouring', true)
    tourDriver.destroy()
  }
}

export const continueTour = ({ step = null }) => {
  if (store.state.tour.isTouring && tourDriver) {
    store.dispatch('tour/pauseTouring', false)
    if (step) tourDriver.drive(step)
  }
}

export const isActiveTourStep = step => step === tourDriver?.getActiveIndex() ?? false

export const destroyTour = () => {
  if (store.state.tour.isTouring && tourDriver) {
    localStorage.setItem('tourVisited', true)
    tourDriver.destroy()
  }
}

// Get Tour Steps
const setupSteps = (DRIVER, tourService) => (userType === 'owner' ? ownerTourSteps(DRIVER, tourService) : memberTourSteps(DRIVER, tourService))

const popOverRender = popover => {
  const driverTitle = document.getElementById('driver-popover-title')
  const driverDescription = document.getElementById('driver-popover-description')

  // Ruler
  const ruler = document.createElement('hr')
  ruler.classList.add('popover-hr')
  driverTitle.append(ruler)
  driverTitle.classList.add('text-primary')

  const lowerRuler = ruler.cloneNode()
  lowerRuler.classList.add('w-50')
  driverDescription.append(lowerRuler)
  driverDescription.setAttribute('style', 'color: #555;')

  // Footer
  // Close Button
  const closeBtn = document.createElement('button')
  closeBtn.innerText = 'Close'
  closeBtn.classList.add('btn-danger', 'popover-btn', 'position-absolute', 'left-0', 'ml-2')
  popover.footerButtons.prepend(closeBtn)

  // Close Button Click Event
  closeBtn.addEventListener('click', () => {
    store.dispatch('tour/setTouring', false)
    tourDriver.destroy()
    localStorage.setItem('tourVisited', true)
  })

  // Previous Button
  const prevBtn = document.getElementsByClassName('driver-popover-prev-btn')[0]
  prevBtn.classList.add('btn-secondary', 'popover-btn')

  // Next Button
  const nextBtn = document.getElementsByClassName('driver-popover-next-btn')[0]
  nextBtn.classList.add('btn-primary', 'popover-btn')

  if (tourDriver.isLastStep()) {
    nextBtn.classList.add('driver-last-step')
  } else nextBtn.classList.add('driver-popover-next')
}

const onDestroyed = () => {
  setTimeout(() => {
    if (!store.state.tour.isTouringPaused) {
      store.dispatch('tour/setTouring', false)
      tourDriver.destroy()
      localStorage.setItem('tourVisited', true)
    }
  }, 250)
}

export const createTourDriver = router => {
  tourDriver = driver({
    showProgress: false,
    popoverClass: 'tour',
    disableActiveInteraction: true,
    nextBtnText: 'Next',
    prevBtnText: 'Previous',
    onPopoverRender: popover => popOverRender(popover),
    onDestroyed,
  })

  const tourService = tourHelper(tourDriver, store, router)
  const steps = setupSteps(tourDriver, tourService)
  tourDriver.setSteps(steps)
  return tourDriver
}
