export const ownerTourIndexes = {
  TOUR_START: 0,
  TOUR_CREATE_A_PROJECT: 1,
  TOUR_SELECT_A_PROJECT: 2,
  TOUR_USER_DROPDOWN: 3,
  TOUR_CHANGE_PROJECT: 4,
  TOUR_INVITE_USERS: 5,
  TOUR_USERS_LIST: 6,
  TOUR_PERMISSIONS_BTN: 7,
  TOUR_PERMISSIONS_FORM: 8,
  TOUR_MONITOR_USERS: 9,
  TOUR_HOURS_TRACKED: 10,
  TOUR_WEEKLY_TIME: 11,
  TOUR_ATTENDANCE: 12,
  TOUR_IDLE_TIME: 13,
  TOUR_MANUAL_TIME: 14,
  TOUR_PRODUCTIVITY: 15,
  TOUR_PAYROLL: 16,
  TOUR_PAYROLL_SETTINGS: 17,
  TOUR_MANAGE_PAYMENT: 18,
  TOUR_GENERATE_INVOICE: 19,
  TOUR_INVOICE_TEMPLATE: 20,
  TOUR_INVOICE_HISTORY: 21,
  TOUR_PAYMENT_HISTORY: 22,
  TOUR_WALLETS: 23,
  TOUR_ADD_WALLET: 24,
  TOUR_WALLET_FAMILY_STATEMENT: 25,
  TOUR_WALLET_ACTIONS: 26,
  TOUR_FUND_TRANSFER: 27,
  TOUR_SCHEDULED_TRANSFERS: 28,
  TOUR_SCHEDULED_TRANSFERS_LIST: 29,
  TOUR_DOWNLOADS: 30,
  TOUR_FINAL: 31,
}

export const memberTourIndexes = {
  TOUR_START: 0,
  TOUR_SELECT_A_PROJECT: 1,
  TOUR_NO_PROJECTS: 2,
  TOUR_USER_DROPDOWN: 3,
  TOUR_CHANGE_PROJECT: 4,
  TOUR_MONITOR_USERS: 5,
  TOUR_HOURS_TRACKED: 6,
  TOUR_WEEKLY_TIME: 7,
  TOUR_ATTENDANCE: 8,
  TOUR_IDLE_TIME: 9,
  TOUR_MANUAL_TIME: 10,
  TOUR_PAYROLL: 11,
  TOUR_PAYMENT_HISTORY: 12,
  TOUR_WALLETS: 13,
  TOUR_ADD_WALLET: 14,
  TOUR_WALLET_FAMILY_STATEMENT: 15,
  TOUR_WALLET_ACTIONS: 16,
  TOUR_FUND_TRANSFER: 17,
  TOUR_SCHEDULED_TRANSFERS: 18,
  TOUR_SCHEDULED_TRANSFERS_LIST: 19,
  TOUR_DOWNLOADS: 20,
  TOUR_FINAL: 21,
}
