import i18n from '@/libs/i18n'
import store from '@/store'

import { memberTourIndexes } from './tourConstants'
// eslint-disable-next-line import/no-cycle
import { checkComponentReady, pauseTour } from './tour'
import { hideOverlay, showOverlay } from './tourHelper'

const _t = (path, page) => i18n.t(`tour.member.${page}.${path}`)

const memberTourSteps = (tourDriver, tourService) => [
  {
    index: memberTourIndexes.TOUR_START,
    element: '#tour',
    popover: {
      title: _t('step 1.title', 'project-roadblock'),
      description: _t('step 1.description', 'project-roadblock'),
      onNextClick: async () => {
        const projectCount = await store.state.tour.projectCount
        checkComponentReady()
        tourDriver.drive(projectCount ? memberTourIndexes.TOUR_SELECT_A_PROJECT : memberTourIndexes.TOUR_NO_PROJECTS)
      },
    },
  },
  {
    index: memberTourIndexes.TOUR_SELECT_A_PROJECT,
    element: '#tour-select-project',
    popover: {
      title: _t('step 2.title', 'project-roadblock'),
      description: _t('step 2.description', 'project-roadblock'),
      nextBtnText: 'Done',
      onNextClick: () => { pauseTour() },
    },
  },
  {
    index: memberTourIndexes.TOUR_NO_PROJECTS,
    element: '#tour-select-project',
    popover: {
      title: _t('step 3.title', 'project-roadblock'),
      description: _t('step 3.description', 'project-roadblock'),
      nextBtnText: 'Done',
      onNextClick: () => { pauseTour() },
    },
  },
  {
    index: memberTourIndexes.TOUR_USER_DROPDOWN,
    element: '#tour-user-dropdown',
    popover: {
      title: _t('step 1.title', 'navbar'),
      description: _t('step 1.description', 'navbar'),
      onPrevClick: async () => {
        showOverlay()
        tourService.clickBtn('tour-change-project')
        tourDriver.movePrevious()
        hideOverlay()
      },
      onNextClick: () => {
        tourService.openModal('tour-user-dropdown__BV_toggle_', 'go-next', 'dropdown')
      },
      disableButtons: ['previous'],
    },
  },
  {
    index: memberTourIndexes.TOUR_CHANGE_PROJECT,
    element: '#tour-change-project',
    popover: {
      title: _t('step 2.title', 'navbar'),
      description: _t('step 2.description', 'navbar'),
      onPrevClick: () => {
        tourService.closeModal('tour-user-dropdown__BV_toggle_', null, 'dropdown')
        tourDriver.movePrevious()
      },
      onNextClick: async () => {
        showOverlay()
        tourService.closeModal('tour-user-dropdown__BV_toggle_', null, 'dropdown')
        await tourService.pushRouter('home')
        tourDriver.moveNext()
        hideOverlay()
      },
    },
  },
  {
    index: memberTourIndexes.TOUR_MONITOR_USERS,
    element: '#tour-monitor-users',
    popover: {
      title: _t('step 1.title', 'home'),
      description: _t('step 1.description', 'home'),
      onPrevClick: async () => {
        tourService.openModal('tour-user-dropdown__BV_toggle_', 'go-prev', 'dropdown')
      },
      onNextClick: async () => {
        showOverlay()
        await tourService.pushRouter('hours-tracked')
        tourDriver.moveNext()
        hideOverlay()
      },
    },
  },
  {
    index: memberTourIndexes.TOUR_HOURS_TRACKED,
    element: '#tour-hours-tracked',
    popover: {
      title: _t('step 1.title', 'reports'),
      description: _t('step 1.description', 'reports'),
      onPrevClick: async () => {
        showOverlay()
        await tourService.pushRouter('home')
        tourDriver.movePrevious()
        hideOverlay()
      },
      onNextClick: async () => {
        showOverlay()
        await tourService.pushRouter('timeline')
        tourDriver.moveNext()
        tourDriver.refresh()
        hideOverlay()
      },
    },
  },
  {
    index: memberTourIndexes.TOUR_WEEKLY_TIME,
    element: '#tour-weekly-time',
    popover: {
      title: _t('step 2.title', 'reports'),
      description: _t('step 2.description', 'reports'),
      onPrevClick: async () => {
        showOverlay()
        await tourService.pushRouter('hours-tracked')
        tourDriver.movePrevious()
        hideOverlay()
      },
      onNextClick: async () => {
        showOverlay()
        await tourService.pushRouter('attendance')
        tourDriver.moveNext()
        hideOverlay()
      },
    },
  },
  {
    index: memberTourIndexes.TOUR_ATTENDANCE,
    element: '#tour-attendance',
    popover: {
      title: _t('step 3.title', 'reports'),
      description: _t('step 3.description', 'reports'),
      onPrevClick: async () => {
        showOverlay()
        await tourService.pushRouter('timeline')
        tourDriver.movePrevious()
        hideOverlay()
      },
      onNextClick: async () => {
        showOverlay()
        await tourService.pushRouter('idle-time')
        tourDriver.moveNext()
        window.scrollTo(0, 0)
        hideOverlay()
      },
    },
  },
  {
    index: memberTourIndexes.TOUR_IDLE_TIME,
    element: '#tour-idle-time',
    popover: {
      title: _t('step 4.title', 'reports'),
      description: _t('step 4.description', 'reports'),
      onPrevClick: async () => {
        showOverlay()
        await tourService.pushRouter('attendance')
        tourDriver.movePrevious()
        hideOverlay()
      },
      onNextClick: async () => {
        showOverlay()
        await tourService.pushRouter('manual-time')
        tourDriver.moveNext()
        hideOverlay()
      },
    },
  },
  {
    index: memberTourIndexes.TOUR_MANUAL_TIME,
    element: '#tour-manual-time',
    popover: {
      title: _t('step 5.title', 'reports'),
      description: _t('step 5.description', 'reports'),
      onPrevClick: async () => {
        showOverlay()
        await tourService.pushRouter('idle-time')
        tourDriver.movePrevious()
        hideOverlay()
      },
      onNextClick: async () => {
        showOverlay()
        await tourService.pushRouter('payments')
        tourDriver.moveNext()
        hideOverlay()
      },
    },
  },
  {
    index: memberTourIndexes.TOUR_PAYROLL,
    element: '#tour-payroll',
    popover: {
      title: _t('step 1.title', 'payroll'),
      description: _t('step 1.description', 'payroll'),
      onPrevClick: async () => {
        showOverlay()
        await tourService.pushRouter('manual-time')
        tourDriver.movePrevious()
        hideOverlay()
      },
      onNextClick: async () => {
        showOverlay()
        await tourService.pushRouter('payment-history')
        tourDriver.moveNext()
        hideOverlay()
      },
    },
  },
  {
    index: memberTourIndexes.TOUR_PAYMENT_HISTORY,
    element: '#tour-payment-history',
    popover: {
      title: _t('step 2.title', 'payroll'),
      description: _t('step 2.description', 'payroll'),
      onPrevClick: async () => {
        showOverlay()
        await tourService.pushRouter('payments')
        await checkComponentReady()
        tourDriver.movePrevious()
        hideOverlay()
      },
      onNextClick: async () => {
        showOverlay()
        await tourService.pushRouter('wallet')
        tourDriver.moveNext()
        tourDriver.refresh()
        hideOverlay()
      },
    },
  },
  {
    index: memberTourIndexes.TOUR_WALLETS,
    element: '#tour-wallets',
    popover: {
      title: _t('step 1.title', 'accounts'),
      description: _t('step 1.description', 'accounts'),
      onPrevClick: async () => {
        showOverlay()
        await tourService.pushRouter('payment-history')
        tourDriver.movePrevious()
        hideOverlay()
      },
      onNextClick: () => {
        tourService.openModal('add-new-wallet-btn', 'go-next')
      },
    },
  },
  {
    index: memberTourIndexes.TOUR_ADD_WALLET,
    element: '#tour-add-wallet',
    popover: {
      title: _t('step 2.title', 'accounts'),
      description: _t('step 2.description', 'accounts'),
      onPrevClick: () => {
        tourService.openModal('add-new-wallet-btn', 'go-prev')
      },
    },
  },
  {
    index: memberTourIndexes.TOUR_WALLET_FAMILY_STATEMENT,
    element: '#tour-family-statement',
    popover: {
      title: _t('step 3.title', 'accounts'),
      description: _t('step 3.description', 'accounts'),
    },
  },
  {
    index: memberTourIndexes.TOUR_WALLET_ACTIONS,
    element: '#tour-account-actions',
    popover: {
      title: _t('step 4.title', 'accounts'),
      description: _t('step 4.description', 'accounts'),
    },
  },
  {
    index: memberTourIndexes.TOUR_SCHEDULED_TRANSFERS,
    element: '#tour-scheduled-transfers',
    popover: {
      title: _t('step 5.title', 'accounts'),
      description: _t('step 5.description', 'accounts'),
      onNextClick: async () => {
        showOverlay()
        await tourService.clickBtn('tour-scheduled-transfers')
        await checkComponentReady()
        tourDriver.moveNext()
        hideOverlay()
      },
    },
  },
  {
    index: memberTourIndexes.TOUR_SCHEDULED_TRANSFERS_LIST,
    element: '#tour-scheduled-transfers-list',
    popover: {
      title: _t('step 6.title', 'accounts'),
      description: _t('step 6.description', 'accounts'),
      onPrevClick: async () => {
        const backBtn = document.getElementById('tour-scheduled-back-btn')
        if (backBtn) {
          showOverlay()
          backBtn.click()
          await checkComponentReady()
          tourDriver.movePrevious()
          hideOverlay()
        }
      },
      onNextClick: async () => {
        showOverlay()
        await tourService.pushRouter('downloads')
        tourDriver.moveNext()
        hideOverlay()
      },
    },
  },
  {
    index: memberTourIndexes.TOUR_DOWNLOADS,
    element: '#tour-downloads',
    popover: {
      title: _t('step 1.title', 'downloads'),
      description: _t('step 1.description', 'downloads'),
      onPrevClick: async () => {
        showOverlay()
        await tourService.pushRouter('wallet')
        await tourService.clickBtn('tour-scheduled-transfers')
        await checkComponentReady()
        tourDriver.movePrevious()
        hideOverlay()
      },
    },
  },
  {
    index: memberTourIndexes.TOUR_FINAL,
    element: '#final',
    popover: {
      title: _t('step 1.title', 'final'),
      description: _t('step 1.description', 'final'),
    },
  },
]

export default memberTourSteps
